import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const Photography = (props) => {

  const { modules, title, description=false, id } = mapPageData(props.data?.page)

  // console.log(props)
  const [modulesToRender, heroModule ] = useModules(modules, { getHeroModule: true })

  return (
  <>
  <SEO title={title} description={description}/>
  <Layout 
    staticMenuLayout={true}
    homeLink={true}
    staticMenu={true}
    heroModule={heroModule}
    className="static-page photography "
    heroClassName="photography">
      {/* Content goes here */}
      { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}

      <PageNav 
        prev={{ text: "Our Colour Palette", linkTo: "/ourcolourpalette" }} 
        next={{  text: "Typography", linkTo: "/typography"}}
      />
  </Layout>
  </>
)}

export default Photography


export const PhotographyQuery = graphql`
  query PhotographyQuery {
  page: contentfulPage(pageId: {eq: "photography-principles"}) {
      ...PageFragment
    }
  }
`
